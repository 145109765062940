import {
  Route,
  Routes,
  RedirectFunction,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
// import store from "./stores/pages/store";
import Stores from "./stores/pages/stores";
// import "bootstrap/dist/css/bootstrap.min.css";
import React, { useCallback, useDebugValue, useEffect, useState } from "react";
import Support from "./landingPages/support";
import Checkout from "./products/pages/checkout.js";
import PlaceOrder from "./products/pages/placeorder";
import { CheckoutProvider } from "./shared/context/checkout-context";
import Ordersuccess from "./products/pages/order-success.js";
import OrderStatus from "./services/orderStatus.js";
import getUserLocation from "./services/location.js"; // Adjust the path as necessary
import TermsAndConditions from "./legal/termsAConditions";
import UserStoreProducts from "./products/pages/userStoreProducts.js";
import ProductMore from "./stores/productMore.js";
import Cookies from "js-cookie";

import { faShoppingCart, faShop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";

import ClientNav from "shared/navigation/clientNav";
import { InstagramIcon, LinkedinIcon, TwitterIcon } from "lucide-react";
import LoginPage from "login/loginPage";
import SignupPage from "login/signupPage";
import Dashboard from "loggedIn/dashboard";
import ProtectedRoute from "protectedRoute";
import SavedOrders from "loggedIn/savedOrders";
import NewSavedOrders from "loggedIn/newSavedOrders";
import MyAccount from "loggedIn/myAccount";
import MyAddress from "loggedIn/myAddress";
import EditSavedOrder from "loggedIn/editSavedOrder";
import OneClick from "loggedIn/oneClick";
import PastOrders from "loggedIn/pastOrders";
import MyCards from "loggedIn/myCards";
import AddPaymentMethod from "loggedIn/addCard";
import LoginOr from "cust-conv/loginor";
import Extra_Explanation from "products/pages/extra_explanation";

const App = () => {
  const [storeId, setStoreId] = useState(false);
  const [zipcode, setZipcode] = useState(48188);
  const [userLatitude, setUserLatitude] = useState(42.3047219);
  const [userLongitude, setUserLongitude] = useState(-83.4819418);
  const [loading, setLoading] = useState(true);
  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const handleCookieConsent = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowCookieConsent(false);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const isCheckoutPage = location.pathname === "/cart";
  const isLoginPage = location.pathname === "/auth/login" || "/auth/signup"; // Identifies if the current page is the login page

  const hideButtonPaths = [
    "/placeorder",
    "/order-success",
    "/ordertracking",
    "/support",
  ];
  const shouldHideButton = hideButtonPaths.includes(location.pathname);

  const navigateToCheckout = () => navigate("/cart");
  const navigateToShop = () => navigate("/");

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent === "true") {
      setShowCookieConsent(false);
    }
    const storedLatitude = Cookies.get("userLatitude");
    const storedLongitude = Cookies.get("userLongitude");
    const storedZipcode = Cookies.get("zipcode");

    if (storedLatitude && storedLongitude && storedZipcode) {
      setUserLatitude(storedLatitude);
      setUserLongitude(storedLongitude);
      setZipcode(storedZipcode);
      setLoading(false);
      console.log("got from cookie");
    } else {
      const handleSuccess = async (coords) => {
        setUserLatitude(coords.latitude);
        setUserLongitude(coords.longitude);
        Cookies.set("userLatitude", coords.latitude, { expires: 7 });
        Cookies.set("userLongitude", coords.longitude, { expires: 7 });

        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/geocode/getZipcode`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                latitude: coords.latitude,
                longitude: coords.longitude,
              }),
            }
          );
          const data = await response.json();
          setZipcode(data.zipcode);
          Cookies.set("zipcode", data.zipcode, { expires: 7 });
          console.log("got from api");
          setLoading(false);
        } catch (error) {
          console.error("Error fetching zipcode:", error);
          setZipcode("48188");
          Cookies.set("zipcode", "48188", { expires: 7 });
        }
      };

      const handleError = (error) => {
        console.error("Location Error:", error);
        setLoading(false);
      };

      getUserLocation(handleSuccess, handleError);
    }
  }, []);

  let routes = (
    <React.Fragment>
      <Route path="/:storeId" Component={UserStoreProducts} exact />
      <Route path="/:storeId/products" Component={UserStoreProducts} exact />
      <Route path="/product/more/:pid" element={<ProductMore />} />
      <Route path="/support" element={<Support />} />
      <Route path="/cart" element={<Checkout />} />
      <Route path="/placeorder" element={<PlaceOrder />} />
      <Route path="/order-success" element={<Ordersuccess />} />
      <Route path="/ordertracking" element={<OrderStatus />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/auth/login" element={<LoginPage />} />
      <Route path="/auth/signup" element={<SignupPage />} />
      <Route path="/whyextra" element={<Extra_Explanation />} />
      <Route
        path="/loginor"
        element={<LoginOr onGuestCheckout={() => {}} />}
      />{" "}
      <Route
        path="/cu/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cu/savedorders"
        element={
          <ProtectedRoute>
            <SavedOrders />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cu/savedorders/new"
        element={
          <ProtectedRoute>
            <NewSavedOrders
              userLatitude={userLatitude}
              userLongitude={userLongitude}
              userZipcode={zipcode}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cu/myaccount"
        element={
          <ProtectedRoute>
            <MyAccount />
          </ProtectedRoute>
        }
      />
      <Route
        path="cu/myaddresses"
        element={
          <ProtectedRoute>
            <MyAddress />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cu/savedorders/edit/:orderId"
        element={
          <ProtectedRoute>
            <EditSavedOrder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cu/mycards"
        element={
          <ProtectedRoute>
            <MyCards />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cu/addpaymentmethod"
        element={
          <ProtectedRoute>
            <AddPaymentMethod />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cu/oneclick"
        element={
          <ProtectedRoute>
            <OneClick />
          </ProtectedRoute>
        }
      />
      <Route
        path="/cu/pastorders"
        element={
          <ProtectedRoute>
            <PastOrders />
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <Stores
            userLatitude={userLatitude}
            userLongitude={userLongitude}
            userZipcode={zipcode}
          />
        }
        exact
      />
      <Route element={<Navigate to="/" />} />
    </React.Fragment>
  );

  return (
    <div
      className="app"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      {/* <div class="bg-black text-white flex justify-center p-4">
        <p class="text-2xl font-bold">
          - Free Shipping <span class="text-blue-400">till Oct 6 - </span>
        </p>
      </div> */}

      <CheckoutProvider>
        <div className="app d-flex flex-column" style={{ minHeight: "100vh" }}>
          <ClientNav
            zipcode={zipcode}
            setZipcode={setZipcode}
            setUserCoordinates={(lat, long) => {
              setUserLatitude(lat);
              setUserLongitude(long);
            }}
          />

          <Routes>{routes}</Routes>
        </div>
      </CheckoutProvider>
      <footer className="bg-black text-white py-3 mt-auto">
        {!shouldHideButton && window.innerWidth < 768 && (
          <button
            className="floating-checkout-btn w-20 h-20 rounded-full bg-black text-white shadow-lg  transition-colors duration-300 "
            onClick={isCheckoutPage ? navigateToShop : navigateToCheckout}
          >
            <div className="btn-content flex flex-col items-center justify-center h-full ">
              <span className="text-xl mb-1 font-bold ">
                {isCheckoutPage ? "Shop" : "Cart"}
              </span>
              {/* <FontAwesomeIcon
                icon={isCheckoutPage ? faShop : faShoppingCart}
                className="text-xl"
              /> */}
            </div>
          </button>
        )}
        <footer className="bg-black text-white p-8 grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <div className="grid gap-4">
            <h3 className="text-lg font-semibold">Customer</h3>
            <nav className="grid gap-2">
              <Link to="/" className="hover:text-gray-300" prefetch={false}>
                Market Place
              </Link>
              <Link
                to="/ordertracking"
                className="hover:text-gray-300"
                prefetch={false}
              >
                Order Tracking
              </Link>
              <Link
                to="/support"
                className="hover:text-gray-300"
                prefetch={false}
              >
                Support
              </Link>
            </nav>
          </div>
          <div className="grid gap-4">
            <h3 className="text-lg font-semibold">Policies</h3>
            <nav className="grid gap-2">
              <Link
                to="/terms-and-conditions"
                className="hover:text-gray-300"
                prefetch={false}
              >
                Terms and Conditions
              </Link>
            </nav>
          </div>
          <div className="grid gap-4">
            <h3 className="text-lg font-semibold">Follow Us</h3>
            <nav className="flex gap-4">
              <a
                href="https://www.instagram.com/spockal/"
                target="_blank"
                className="hover:text-gray-300"
                rel="noopener noreferrer"
              >
                <InstagramIcon className="h-6 w-6" />
              </a>
              <a
                href="https://www.linkedin.com/company/spockal"
                target="_blank"
                className="hover:text-gray-300"
                rel="noopener noreferrer"
              >
                <LinkedinIcon className="h-6 w-6" />
              </a>
              <a
                href="https://twitter.com/spockalus"
                target="_blank"
                className="hover:text-gray-300"
                rel="noopener noreferrer"
              >
                <TwitterIcon className="h-6 w-6" />
              </a>
            </nav>
          </div>
          <div className="mt-12 border-t border-gray-200 pt-6 text-center text-sm text-white dark:border-gray-700 dark:text-gray-400">
            &copy; 2024 Spockal. All rights reserved.
          </div>
        </footer>
      </footer>
    </div>
  );
};

export default App;
