import { Scale, ShoppingBag, CreditCard } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";

export default function Extra_Explanation() {
  return (
    <div className="min-h-screen bg-gray-50/50 p-4 md:p-8">
      <div className="mx-auto max-w-3xl">
        <div className="mb-8 text-center">
          <h1 className="text-3xl font-bold tracking-tight mb-4">
            Understanding Weight-Based Pricing
          </h1>
          <p className="text-muted-foreground">
            Learn how we handle pricing for items sold by weight
          </p>
        </div>

        <Card className="mb-8">
          <CardContent className="p-6">
            <p className="text-lg leading-relaxed mb-6">
              Some products (like meats and produce) are priced by weight. Since
              the exact weight can vary, we add a buffer to cover potential
              differences. You'll only be charged for the actual weight of your
              items.
            </p>

            <div className="grid gap-8 md:grid-cols-3 mt-8">
              <div className="flex flex-col items-center text-center gap-4">
                <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center">
                  <Scale className="h-6 w-6 text-primary" />
                </div>
                <h3 className="font-semibold">Variable Weight</h3>
                <p className="text-sm text-muted-foreground">
                  Products like meat and produce can vary in weight from piece
                  to piece
                </p>
              </div>

              <div className="flex flex-col items-center text-center gap-4">
                <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center">
                  <ShoppingBag className="h-6 w-6 text-primary" />
                </div>
                <h3 className="font-semibold">Buffer Added</h3>
                <p className="text-sm text-muted-foreground">
                  We estimate slightly high to ensure we can fulfill your order
                </p>
              </div>

              <div className="flex flex-col items-center text-center gap-4">
                <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center">
                  <CreditCard className="h-6 w-6 text-primary" />
                </div>
                <h3 className="font-semibold">Fair Charging</h3>
                <p className="text-sm text-muted-foreground">
                  You only pay for the actual weight of items you receive
                </p>
              </div>
            </div>
          </CardContent>
        </Card>

        <div className="bg-primary/5 border rounded-lg p-6">
          <h2 className="font-semibold mb-4">Example:</h2>
          <div className="space-y-4">
            <p className="text-sm">1. You order a whole chicken</p>
            <p className="text-sm">
              2. A whole chicken can be 3 pounds, 4 pounds or 2.5 pounds. So to
              cover this possibility, we add a buffer to account for this
              variability.
            </p>
            <p className="text-sm">
              3. You'll only be charged for the actual weight of your items.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
